import React from "react"
import Head from '../components/head'
import { Link } from "gatsby"
import {
  Card,
  Typography,
  CardActions,
  CardContent,
} from "@material-ui/core"
import Layout from "../components/layout"
import thumb from '../images/hi_sa_live_forsns-default.jpg'
import thumb2 from '../images/yf_sl_sns_md.jpg'
import Footer from "../components/footer"
import Header from "../components/header"

export default function Home() {
  return (
    <Layout>
    <div>
        <Head />
        <Header/>
        <Typography>tipple MUSICはオンラインでのライブ配信などの収益化を目指したサービスです。現在はベータ版として限定的に試験運用を開始した段階ですが、目的は誰でもオンラインでの表現で収益を実現できるようにする予定です。</Typography>
        <h3>準備中</h3>
        {/*
          <Link to='/payments/yfsl0306'>
            <Card>
              <CardContent>
                <img src={thumb2} className="thumb"/>
              </CardContent>
            </Card>
          </Link>
          <Link to='/payments/dfgfadfc'>
          <Card style={{marginTop:'2rem'}}>
            <CardContent>
              <img src={thumb} className="thumb"/>
            </CardContent>
          </Card>
        </Link>
        */}
      </div>
      <Footer/>
      </Layout>
  )
}
